<template>
    <div class="ui p-half clearing red segment">
<!--            <div class="ui right floated primary button" @click="$router.push({name: 'userCrud'})"> nouveau </div>-->
        <div class="downloadTab">
            <h3 class="ui left floated header m-0"> Liste des utilisateurs </h3>
            <span class="right floated flex items-end" @click="$router.push({name: 'userCrud'})">
                <svg
                        width="20"
                        height="20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0)">
                        <path
                                d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                fill="#0953AA"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0h20v20H0z"/>
                        </clipPath>
                    </defs>
                </svg>
                Ajouter
            </span>
        </div>

        <div class="ui accordion">

            <div class="title">
                <i class="dropdown icon"></i>
                <u>Recherche</u>
            </div>
            <div class="content">
                <div class="ui segment">
                    <div class="ui stackable grid m-0">
                        <div class="ui row">
                            <div class="sixteen wide column">
                                <h3 class="ui dividing padded header app-blue">
                                    Recherche
                                </h3>
                            </div>
                        </div>
                        <div class="ui row">
                            <div class="twelve wide column">
                                <div class="ui equal width form">
                                    <div class="two fields">
                                        <div class="field">
                                            <label>Nom</label>
                                            <input type="text" placeholder="Nom" v-model="searchQuery.lastname">
                                        </div>
                                        <div class="field">
                                            <label>Prénom</label>
                                            <input type="text" placeholder="Prénom" v-model="searchQuery.firstname">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="four wide column" style="margin-top: auto;margin-bottom: auto;">
                                <button class="ui positive right labeled icon button"
                                        style="border-radius: 6px;" @click="getUsers">
                                    <i class="search icon"></i>
                                    Chercher
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>

        <table class="ui selectable celled table">
            <thead>
            <tr>
                <th class="collapsing">Actions</th>
                <th @click="sortFolders('username')">
                    Username <i v-if="sort.username" class="long arrow alternate icon float-right"
                                :class="sort.username === 1 ? 'up' : 'down'"></i>
                </th>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Email</th>
                <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!user_list.length"><td> Pas de données disponibles </td> </tr>
            <tr v-for="user in user_list">
                <td>
                    <div class="ui primary tiny button" @click="edit(user)"> Editer</div>
                </td>
                <td> {{ user.username }}</td>
                <td> {{ user.lastname }}</td>
                <td> {{ user.firstname }}</td>
                <td> {{ user.email }}</td>
                <td> {{ user.type }}</td>
            </tr>
            </tbody>

            <tfoot>
                <tr>
                    <th colspan="99">
                        <div>
                            <pagination :limit="limit" :current_page="page" :count="count" @setPage="setPage" slot="pagination"/>
                        </div>
                    </th>
                </tr>
            </tfoot>

        </table>

    </div>
</template>

<script>
    import pagination from "@/components/pagination.vue";
    export default {
        name: "userList",
        components:{
            pagination
        },
        data() {
            return {
                user_list: [],
                page: 1,
                limit: 10,
                count: 0,
                searchQuery: {
                    firstname: null,
                    lastname: null
                },
                sort: {'username': 1}
            }
        },
        methods: {
            getUsers() {
                console.log('getUsers');
                let match = {};
                if(this.searchQuery.firstname){
                    match.firstname = {$regex: this.searchQuery.firstname}
                }
                if(this.searchQuery.lastname){
                    match.lastname = {$regex: this.searchQuery.lastname}
                }

                let data = {query: match, options: {page: parseInt(this.page), limit: parseInt(this.limit)}};

                if (!_.isEmpty(this.sort)) {
                    data.options.sort = JSON.parse(JSON.stringify(this.sort))
                }
                this.$store.dispatchAsync(this.$SHARED.services.user.list, data).then(ar => {
                    this.user_list = ar.docs;
                    this.count = ar.count;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            sortFolders(field) {
                if (Object.keys(this.sort)[0] !== field) {
                    this.sort = {};
                    this.sort[field] = -1
                }

                if (this.sort[field] === 1) {
                    this.sort[field] = -1
                } else {
                    this.sort[field] = 1
                }

                this.getUsers();
            },
            edit(user){
                this.$router.push({name: 'userCrud', params:{user}});
            },
            setPage(page){
                this.page = page;
                this.getUsers();
            }
        },
        mounted() {
            this.getUsers();
            $('.ui.accordion').accordion({exclusive:true});
        }
    }
</script>

<style scoped>

</style>